@import '../colours';

$govuk-footer-background: $govuk-canvas-background-colour;
$govuk-footer-border: $govuk-border-colour;

$govuk-footer-border-top: $govuk-border-colour;
$govuk-footer-text: $govuk-text-colour;
$govuk-footer-link-hover-colour: null;

.govuk-footer {
  border-top: 1px solid $govuk-footer-border-top;
  color: $govuk-footer-text;
  background: $govuk-footer-background;
}

.govuk-footer__section-break {
  border-bottom: 1px solid $govuk-footer-border;
}

.govuk-footer__copyright-logo {
  display: inline-block;
  background-image: govuk-image-url("govuk-crest.svg");
  text-align: center;
  white-space: nowrap;
}

.govuk-footer__heading {
  border-bottom: 1px solid $govuk-footer-border;
}

.govuk-footer__link {
  color: $govuk-footer-text;
  text-decoration: underline;
  transition: all 0.2s ease;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 2px;

  &:focus,
  &:hover {
    color: #FFFFFF; 
    background-color: #0D7CBB; 
    outline: 2px solid #FFFFFF;
    text-decoration: underline;
  }
}
